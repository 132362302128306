import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';

const ShiftsData = ({ shifts, loading, setTimeInput }) => {
    const [sh_id, setShId] = useState(null)
    console.log("ShiftsData0000", sh_id);


    useEffect(() => {
        setShId(null)
        setTimeInput(null)
    }, [shifts])

    return (
        loading ?
            <div className="checkout_loader mt-0" >
                <div className="loader"></div>
            </div>
            :
            <Accordion
                multiple
                expandIcon="fa fa-plus"
                collapseIcon="fa fa-minus"
                className="mb-4 mt-4"

            >

                {
                    shifts?.map((item) => {
                        const currentTime = new Date();
                        const currentTimeWithAdvance = new Date(currentTime.getTime() + item?.minutes_in_advance * 60000);

                        // حساب الفرق بالأيام بين التاريخ الحالي و shift_start_hour
                        const shiftStartDate = new Date(item?.shift_start_hour);
                        const differenceInDays = Math.floor((shiftStartDate - currentTime) / (1000 * 60 * 60 * 24));

                        // تصفية الأوقات التي تأتي بعد currentTimeWithAdvance فقط
                        const validIntervals = item?.shift_online_active_intervals?.filter(sh => {
                            const startTime = new Date(`${shiftStartDate.toISOString().split('T')[0]}T${sh.start_time}:00`);
                            return startTime >= currentTimeWithAdvance;
                        });

                        // عرض AccordionTab فقط إذا كانت هناك أوقات متاحة وكان shift_start_hour ضمن days_in_advance
                        return validIntervals.length > 0 && differenceInDays <= item?.days_in_advance && (
                            <AccordionTab
                                key={item?.shift_id}
                                header={item?.shift_name}
                                disabled={false}
                            >
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {
                                        validIntervals.map((sh, idx) => (
                                            <Button
                                                key={`${item.shift_id}-${sh.start_time}`}
                                                className={`reg-btn d-flex justify-content-center sh_btn ${sh_id == `${item.shift_id}-${sh.start_time}` ? "sh_full" : ""}`}
                                                style={{ width: "31%", margin: "10px 1%", flexDirection: "column", alignItems: "center", padding: "3px", minWidth: "auto" }}
                                                onClick={() => {
                                                    setShId(`${item.shift_id}-${sh.start_time}`);
                                                    setTimeInput(sh.start_time);
                                                }}
                                            >
                                                <span>{sh.start_time}</span>
                                                <span>{sh?.sections[0]?.name}</span>
                                            </Button>
                                        ))
                                    }
                                </div>
                            </AccordionTab>
                        );
                    })
                }





            </Accordion>
    )
}

export default ShiftsData